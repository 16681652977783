import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"


class ContactForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailAddress: '',
            emailSubject: '',
            emailMessage: ''
        };
        this.handleEmailAddressChange = this.handleEmailAddressChange.bind(this);
        this.handleEmailSubjectChange = this.handleEmailSubjectChange.bind(this);
        this.handleEmailMessageChange = this.handleEmailMessageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleEmailAddressChange(event) {
        this.setState({ emailAddress: event.target.value });
    }
    handleEmailSubjectChange(event) {
        this.setState({ emailSubject: event.target.value });
    }
    handleEmailMessageChange(event) {
        this.setState({ emailMessage: event.target.value });
    }
    handleSubmit(event) {
        event.preventDefault();
        var email = this.state.emailAddress;
        var subject = this.state.emailSubject;
        var message = this.state.emailMessage;
        var endpoint = 'https://test-hello.com';
        //var endpoint = 'https://k29r047ezc.execute-api.us-east-1.amazonaws.com/default/contact-form-logic';

        var body = {
            email: email,
            subject: subject,
            message: message,
        };
        //TODO MIGUEL: Add some error checking here before sending the message, avoid empty fields
        //maybe display some popup prompting user to correct info
         //TODO MIGUEL: Add Success dialoge and reset the form to make it clear things were sumbitted
        console.log(body);
        var endpointRequest = new Request(endpoint, {
            method: 'POST',
            body: JSON.stringify(body)
        });
        fetch(endpointRequest).then(response => console.log(response)).catch(err => console.error(err));
    }

    render() {
        const inputStyle = {
            display: 'block',
            width: '300',
        }
        return (
            <Layout>
                <SEO title="Contact Me" />
                <h1>Contact me</h1>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        Email
                        <input style={inputStyle} type="email" name="email" value={this.state.emailAddress} onChange={this.handleEmailAddressChange} />
                    </label>
                    <br></br>
                    <label>
                        Subject
                        <input style={inputStyle} type="text" name="subject" value={this.state.emailSubject} onChange={this.handleEmailSubjectChange} />
                    </label>
                    <br></br>
                    <label>
                        Message
                        <textarea style={{ display: 'block', width: '450px', }} name="message" rows="5" value={this.state.emailMessage} onChange={this.handleEmailMessageChange} />
                    </label>
                    <br></br>
                    <button type="submit">Send</button>
                    <input type="reset" value="Clear" />
                </form>
                <Link to="/">Go to home page</Link>
            </Layout>
        );
    }
}

export default ContactForm